export class MenuButton {
    constructor() {
        this.$el = document.querySelector('#menu-button');

        if (!this.$el) return;

        this.$el.addEventListener('click', () => {
            if (document.body.classList.contains('is-menu-open')) {
                document.body.classList.remove('is-menu-open');
                this.$el.innerText = 'Menu';
            } else {
                document.body.classList.add('is-menu-open');
                this.$el.innerText = 'Close';
            }
        })
    }
}