import {sub} from './tools.js';

export class Newsletter {
    constructor() {
        this.$els = document.querySelectorAll('.signup-form');

        if (!this.$els) return;

        this.$els.forEach(el => {
            el.addEventListener('submit', e => {
                sub(e);
            })
        })

    }
}