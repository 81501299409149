import { scrollToY } from "./tools";

export class Footnotes {
    constructor() {
        this.footnotes = document.querySelectorAll('.footnote');
        this.references = document.querySelectorAll('article-footnote');

        this.references.forEach((ref, i) => {
            let fn = document.querySelector(`#fn-${i+1}`);
            ref.addEventListener('click', () => {
                scrollToY(fn.offsetTop, 1500, 'easeInOutQuint', null);
            })
        })
    }
}