import Swiper, {
    Mousewheel, 
    FreeMode, 
    Navigation, 
    Controller, 
    Keyboard,
    Lazy
} from 'swiper';

export class Lightbox {
    constructor(options) {
        this.init();
        this.lightboxOptions = {
            modules: [Mousewheel, Controller, Keyboard, Lazy],
            mousewheel: {
                invert: false,
            },
            keyboard: {
                enabled: true
            },
            breakpoints: {
                640: {
                    direction: 'vertical',
                }
            },
            lazy: {
                // checkInView: true,
                enabled: true,
                loadPrevNext: true,
                loadOnTransitionStart: true,
            },
            preloadImages: false,
        }
        return this;
    }

    open(position) {
        this.lightbox = new Swiper('.s-lightbox', this.lightboxOptions);
        document.body.dataset.lightboxOpen = true,
        this.lightbox.activeIndex = position ?? 0;
        this.lightbox.controller.control = this.gallery;
        this.gallery.controller.control = this.lightbox;
    }

    isOpen() {
        return document.body.dataset.lightboxOpen == 'true';
    }

    close() {
        this.lightbox.destroy();
        this.gallery.controller.control = false;
        document.body.dataset.lightboxOpen = false;
    }

    init() {
        this.closeButton = document.querySelector('.s-lightbox_close-button');
        if (this.closeButton) {
            this.closeButton.addEventListener('click', e => {
                this.close();
            })
        }

        this.gallery = new Swiper('.s-gallery', {
            modules: [Mousewheel, FreeMode, Navigation, Controller],
            slidesPerView: 'auto',
            grabCursor: true,
            centeredSlides: true,
            slideToClickedSlide: true,
            breakpoints: {
                640: {
                    direction: 'vertical',
                }
            },
            mousewheel: {
                invert: false,
            },
            freeMode: {
                enabled: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                click: (swiper, event) => {
                    if (this.isOpen()) return;
                    this.open(swiper.clickedIndex);
                },
                slideChange: swiper => {
                    // lazy doesn't function ootb when scrolling gallery, this fixes it
                    if (!this.isOpen()) return;
                    this.lightbox.lazy.loadInSlide(swiper.activeIndex);
                }
            }
        });
    }
}