export class ProgramElements {
    constructor() {
        this.$els = document.querySelectorAll('.program .program-element');

        if (!this.$els) return;

        this.$els.forEach(el => {
            el.addEventListener('mouseenter', () => {
                el.classList.add('is-highlighted');
                if (!el.dataset.related) return;
                el.dataset.related.split(',').forEach(relatedId => {
                    let related = document.querySelector(`#${relatedId}`);
                    related.classList.add('is-highlighted');
                })
            })
            el.addEventListener('mouseleave', () => {
                el.classList.remove('is-highlighted');
                if (!el.dataset.related) return;
                el.dataset.related.split(',').forEach(relatedId => {
                    let related = document.querySelector(`#${relatedId}`);
                    related.classList.remove('is-highlighted');
                })
            })
        })
    }
}