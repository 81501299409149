import { Footnotes } from "./Footnotes";
import { Lightbox } from "./Lightbox";
import { MenuButton } from "./MenuButton";
import { Newsletter } from "./Newsletter";
import { ProgramElements } from "./ProgramElements";

document.addEventListener('DOMContentLoaded', init);

function init() {

    new Footnotes();
    new Lightbox();
    new MenuButton();
    new Newsletter();
    new ProgramElements();
}